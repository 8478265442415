const countryFlag = {
  malta: 'https://flagcdn.com/mt.svg',
  sweden: 'https://flagcdn.com/se.svg',
  'new caledonia': 'https://flagcdn.com/nc.svg',
  'burkina faso': 'https://flagcdn.com/bf.svg',
  'bosnia and herzegovina': 'https://flagcdn.com/ba.svg',
  jordan: 'https://flagcdn.com/jo.svg',
  'puerto rico': 'https://flagcdn.com/pr.svg',
  greenland: 'https://flagcdn.com/gl.svg',
  iran: 'https://flagcdn.com/ir.svg',
  lesotho: 'https://flagcdn.com/ls.svg',
  latvia: 'https://flagcdn.com/lv.svg',
  madagascar: 'https://flagcdn.com/mg.svg',
  cyprus: 'https://flagcdn.com/cy.svg',
  mauritius: 'https://flagcdn.com/mu.svg',
  eswatini: 'https://flagcdn.com/sz.svg',
  'solomon islands': 'https://flagcdn.com/sb.svg',
  austria: 'https://flagcdn.com/at.svg',
  'vatican city': 'https://flagcdn.com/va.svg',
  malawi: 'https://flagcdn.com/mw.svg',
  botswana: 'https://flagcdn.com/bw.svg',
  djibouti: 'https://flagcdn.com/dj.svg',
  'republic of the congo': 'https://flagcdn.com/cg.svg',
  barbados: 'https://flagcdn.com/bb.svg',
  'sint maarten': 'https://flagcdn.com/sx.svg',
  rwanda: 'https://flagcdn.com/rw.svg',
  jersey: 'https://flagcdn.com/je.svg',
  'bouvet island': 'https://flagcdn.com/bv.svg',
  yemen: 'https://flagcdn.com/ye.svg',
  brazil: 'https://flagcdn.com/br.svg',
  algeria: 'https://flagcdn.com/dz.svg',
  kazakhstan: 'https://flagcdn.com/kz.svg',
  'saint martin': 'https://flagcdn.com/mf.svg',
  curaçao: 'https://flagcdn.com/cw.svg',
  fiji: 'https://flagcdn.com/fj.svg',
  pakistan: 'https://flagcdn.com/pk.svg',
  'south georgia': 'https://flagcdn.com/gs.svg',
  georgia: 'https://flagcdn.com/ge.svg',
  namibia: 'https://flagcdn.com/na.svg',
  'guinea-bissau': 'https://flagcdn.com/gw.svg',
  norway: 'https://flagcdn.com/no.svg',
  'christmas island': 'https://flagcdn.com/cx.svg',
  liechtenstein: 'https://flagcdn.com/li.svg',
  india: 'https://flagcdn.com/in.svg',
  france: 'https://flagcdn.com/fr.svg',
  'hong kong': 'https://flagcdn.com/hk.svg',
  anguilla: 'https://flagcdn.com/ai.svg',
  colombia: 'https://flagcdn.com/co.svg',
  iraq: 'https://flagcdn.com/iq.svg',
  morocco: 'https://flagcdn.com/ma.svg',
  libya: 'https://flagcdn.com/ly.svg',
  'saint lucia': 'https://flagcdn.com/lc.svg',
  'marshall islands': 'https://flagcdn.com/mh.svg',
  'sierra leone': 'https://flagcdn.com/sl.svg',
  'cook islands': 'https://flagcdn.com/ck.svg',
  croatia: 'https://flagcdn.com/hr.svg',
  turkmenistan: 'https://flagcdn.com/tm.svg',
  bhutan: 'https://flagcdn.com/bt.svg',
  'united kingdom': 'https://flagcdn.com/gb.svg',
  monaco: 'https://flagcdn.com/mc.svg',
  palau: 'https://flagcdn.com/pw.svg',
  gabon: 'https://flagcdn.com/ga.svg',
  germany: 'https://flagcdn.com/de.svg',
  japan: 'https://flagcdn.com/jp.svg',
  'norfolk island': 'https://flagcdn.com/nf.svg',
  'central african republic': 'https://flagcdn.com/cf.svg',
  macau: 'https://flagcdn.com/mo.svg',
  syria: 'https://flagcdn.com/sy.svg',
  montserrat: 'https://flagcdn.com/ms.svg',
  spain: 'https://flagcdn.com/es.svg',
  gambia: 'https://flagcdn.com/gm.svg',
  'turks and caicos islands': 'https://flagcdn.com/tc.svg',
  'french southern and antarctic lands': 'https://flagcdn.com/tf.svg',
  'saint pierre and miquelon': 'https://flagcdn.com/pm.svg',
  nauru: 'https://flagcdn.com/nr.svg',
  'faroe islands': 'https://flagcdn.com/fo.svg',
  'saint vincent and the grenadines': 'https://flagcdn.com/vc.svg',
  canada: 'https://flagcdn.com/ca.svg',
  egypt: 'https://flagcdn.com/eg.svg',
  'papua new guinea': 'https://flagcdn.com/pg.svg',
  grenada: 'https://flagcdn.com/gd.svg',
  czechia: 'https://flagcdn.com/cz.svg',
  greece: 'https://flagcdn.com/gr.svg',
  togo: 'https://flagcdn.com/tg.svg',
  australia: 'https://flagcdn.com/au.svg',
  luxembourg: 'https://flagcdn.com/lu.svg',
  niue: 'https://flagcdn.com/nu.svg',
  bahrain: 'https://flagcdn.com/bh.svg',
  netherlands: 'https://flagcdn.com/nl.svg',
  uzbekistan: 'https://flagcdn.com/uz.svg',
  hungary: 'https://flagcdn.com/hu.svg',
  'british virgin islands': 'https://flagcdn.com/vg.svg',
  zambia: 'https://flagcdn.com/zm.svg',
  thailand: 'https://flagcdn.com/th.svg',
  'north macedonia': 'https://flagcdn.com/mk.svg',
  'saint barthélemy': 'https://flagcdn.com/bl.svg',
  niger: 'https://flagcdn.com/ne.svg',
  somalia: 'https://flagcdn.com/so.svg',
  cameroon: 'https://flagcdn.com/cm.svg',
  russia: 'https://flagcdn.com/ru.svg',
  burundi: 'https://flagcdn.com/bi.svg',
  palestine: 'https://flagcdn.com/ps.svg',
  malaysia: 'https://flagcdn.com/my.svg',
  tanzania: 'https://flagcdn.com/tz.svg',
  kuwait: 'https://flagcdn.com/kw.svg',
  nigeria: 'https://flagcdn.com/ng.svg',
  'ivory coast': 'https://flagcdn.com/ci.svg',
  denmark: 'https://flagcdn.com/dk.svg',
  lithuania: 'https://flagcdn.com/lt.svg',
  'cape verde': 'https://flagcdn.com/cv.svg',
  angola: 'https://flagcdn.com/ao.svg',
  finland: 'https://flagcdn.com/fi.svg',
  peru: 'https://flagcdn.com/pe.svg',
  'french polynesia': 'https://flagcdn.com/pf.svg',
  portugal: 'https://flagcdn.com/pt.svg',
  ghana: 'https://flagcdn.com/gh.svg',
  mozambique: 'https://flagcdn.com/mz.svg',
  kiribati: 'https://flagcdn.com/ki.svg',
  'antigua and barbuda': 'https://flagcdn.com/ag.svg',
  'new zealand': 'https://flagcdn.com/nz.svg',
  paraguay: 'https://flagcdn.com/py.svg',
  belize: 'https://flagcdn.com/bz.svg',
  'northern mariana islands': 'https://flagcdn.com/mp.svg',
  slovenia: 'https://flagcdn.com/si.svg',
  armenia: 'https://flagcdn.com/am.svg',
  'united arab emirates': 'https://flagcdn.com/ae.svg',
  'american samoa': 'https://flagcdn.com/as.svg',
  'timor-leste': 'https://flagcdn.com/tl.svg',
  'caribbean netherlands': 'https://flagcdn.com/bq.svg',
  montenegro: 'https://flagcdn.com/me.svg',
  poland: 'https://flagcdn.com/pl.svg',
  'pitcairn islands': 'https://flagcdn.com/pn.svg',
  slovakia: 'https://flagcdn.com/sk.svg',
  bolivia: 'https://flagcdn.com/bo.svg',
  chile: 'https://flagcdn.com/cl.svg',
  antarctica: 'https://flagcdn.com/aq.svg',
  bangladesh: 'https://flagcdn.com/bd.svg',
  mexico: 'https://flagcdn.com/mx.svg',
  mongolia: 'https://flagcdn.com/mn.svg',
  ireland: 'https://flagcdn.com/ie.svg',
  cuba: 'https://flagcdn.com/cu.svg',
  guadeloupe: 'https://flagcdn.com/gp.svg',
  estonia: 'https://flagcdn.com/ee.svg',
  'el salvador': 'https://flagcdn.com/sv.svg',
  afghanistan:
    'https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_the_Taliban.svg',
  uruguay: 'https://flagcdn.com/uy.svg',
  belarus: 'https://flagcdn.com/by.svg',
  'dr congo': 'https://flagcdn.com/cd.svg',
  'western sahara': 'https://flagcdn.com/eh.svg',
  gibraltar: 'https://flagcdn.com/gi.svg',
  guam: 'https://flagcdn.com/gu.svg',
  azerbaijan: 'https://flagcdn.com/az.svg',
  haiti: 'https://flagcdn.com/ht.svg',
  mali: 'https://flagcdn.com/ml.svg',
  'dominican republic': 'https://flagcdn.com/do.svg',
  singapore: 'https://flagcdn.com/sg.svg',
  argentina: 'https://flagcdn.com/ar.svg',
  iceland: 'https://flagcdn.com/is.svg',
  'united states': 'https://flagcdn.com/us.svg',
  jamaica: 'https://flagcdn.com/jm.svg',
  qatar: 'https://flagcdn.com/qa.svg',
  oman: 'https://flagcdn.com/om.svg',
  romania: 'https://flagcdn.com/ro.svg',
  'trinidad and tobago': 'https://flagcdn.com/tt.svg',
  maldives: 'https://flagcdn.com/mv.svg',
  'south sudan': 'https://flagcdn.com/ss.svg',
  aruba: 'https://flagcdn.com/aw.svg',
  'falkland islands': 'https://flagcdn.com/fk.svg',
  'são tomé and príncipe': 'https://flagcdn.com/st.svg',
  andorra: 'https://flagcdn.com/ad.svg',
  panama: 'https://flagcdn.com/pa.svg',
  albania: 'https://flagcdn.com/al.svg',
  honduras: 'https://flagcdn.com/hn.svg',
  mayotte: 'https://flagcdn.com/yt.svg',
  'equatorial guinea': 'https://flagcdn.com/gq.svg',
  'heard island and mcdonald islands': 'https://flagcdn.com/hm.svg',
  senegal: 'https://flagcdn.com/sn.svg',
  'north korea': 'https://flagcdn.com/kp.svg',
  'san marino': 'https://flagcdn.com/sm.svg',
  'british indian ocean territory': 'https://flagcdn.com/io.svg',
  kenya: 'https://flagcdn.com/ke.svg',
  'sri lanka': 'https://flagcdn.com/lk.svg',
  'saint helena, ascension and tristan da cunha': 'https://flagcdn.com/sh.svg',
  lebanon: 'https://flagcdn.com/lb.svg',
  comoros: 'https://flagcdn.com/km.svg',
  martinique: 'https://flagcdn.com/mq.svg',
  taiwan: 'https://flagcdn.com/tw.svg',
  'cayman islands': 'https://flagcdn.com/ky.svg',
  nicaragua: 'https://flagcdn.com/ni.svg',
  'united states virgin islands': 'https://flagcdn.com/vi.svg',
  tokelau: 'https://flagcdn.com/tk.svg',
  switzerland: 'https://flagcdn.com/ch.svg',
  bulgaria: 'https://flagcdn.com/bg.svg',
  tuvalu: 'https://flagcdn.com/tv.svg',
  laos: 'https://flagcdn.com/la.svg',
  brunei: 'https://flagcdn.com/bn.svg',
  guernsey: 'https://flagcdn.com/gg.svg',
  nepal: 'https://flagcdn.com/np.svg',
  'costa rica': 'https://flagcdn.com/cr.svg',
  cambodia: 'https://flagcdn.com/kh.svg',
  'saudi arabia': 'https://flagcdn.com/sa.svg',
  guinea: 'https://flagcdn.com/gn.svg',
  'united states minor outlying islands': 'https://flagcdn.com/um.svg',
  kosovo: 'https://flagcdn.com/xk.svg',
  micronesia: 'https://flagcdn.com/fm.svg',
  israel: 'https://flagcdn.com/il.svg',
  eritrea: 'https://flagcdn.com/er.svg',
  'south korea': 'https://flagcdn.com/kr.svg',
  'åland islands': 'https://flagcdn.com/ax.svg',
  serbia: 'https://flagcdn.com/rs.svg',
  tonga: 'https://flagcdn.com/to.svg',
  venezuela: 'https://flagcdn.com/ve.svg',
  kyrgyzstan: 'https://flagcdn.com/kg.svg',
  'wallis and futuna': 'https://flagcdn.com/wf.svg',
  sudan: 'https://flagcdn.com/sd.svg',
  belgium: 'https://flagcdn.com/be.svg',
  ukraine: 'https://flagcdn.com/ua.svg',
  vanuatu: 'https://flagcdn.com/vu.svg',
  bermuda: 'https://flagcdn.com/bm.svg',
  tunisia: 'https://flagcdn.com/tn.svg',
  mauritania: 'https://flagcdn.com/mr.svg',
  indonesia: 'https://flagcdn.com/id.svg',
  uganda: 'https://flagcdn.com/ug.svg',
  'isle of man': 'https://flagcdn.com/im.svg',
  myanmar: 'https://flagcdn.com/mm.svg',
  china: 'https://flagcdn.com/cn.svg',
  'svalbard and jan mayen': 'https://flagcdn.com/sj.svg',
  seychelles: 'https://flagcdn.com/sc.svg',
  'cocos (keeling) islands': 'https://flagcdn.com/cc.svg',
  zimbabwe: 'https://flagcdn.com/zw.svg',
  benin: 'https://flagcdn.com/bj.svg',
  ethiopia: 'https://flagcdn.com/et.svg',
  'south africa': 'https://flagcdn.com/za.svg',
  guatemala: 'https://flagcdn.com/gt.svg',
  chad: 'https://flagcdn.com/td.svg',
  liberia: 'https://flagcdn.com/lr.svg',
  'french guiana': 'https://flagcdn.com/gf.svg',
  moldova: 'https://flagcdn.com/md.svg',
  suriname: 'https://flagcdn.com/sr.svg',
  bahamas: 'https://flagcdn.com/bs.svg',
  vietnam: 'https://flagcdn.com/vn.svg',
  'saint kitts and nevis': 'https://flagcdn.com/kn.svg',
  samoa: 'https://flagcdn.com/ws.svg',
  dominica: 'https://flagcdn.com/dm.svg',
  réunion: 'https://flagcdn.com/re.svg',
  ecuador: 'https://flagcdn.com/ec.svg',
  tajikistan: 'https://flagcdn.com/tj.svg',
  philippines: 'https://flagcdn.com/ph.svg',
  guyana: 'https://flagcdn.com/gy.svg',
  turkey: 'https://flagcdn.com/tr.svg',
  italy: 'https://flagcdn.com/it.svg'
}

export default countryFlag
