<template>
  <v-container class="pb-10">
    <v-row>
      <v-col>
        <v-sheet class="pa-5 py-8" color="white" elevation="0">
          <p class="text-subtitle-1 font-weight-bold">
            Got questions? Call or Whatsapp {{ siteData.phone }}
          </p>
          <v-row justify="center" align="center">
            <v-card class="pa-2 px-6" elevation="5" rounded="lg">
              <v-card-title
                ><span class="mt-2 text-h5 mx-auto text--primary">
                  Giftcard And Crypto Rate Calculator</span
                ></v-card-title
              >
              <v-card-subtitle class="text-center"
                >Know the current value of your transaction</v-card-subtitle
              >
              <v-divider class="mb-4"></v-divider>
              <v-row>
                <v-col cols="12" md="7">
                  <v-card class="pa-4 px-6" outlined>
                    <v-tabs
                      :vertical="$vuetify.breakpoint.smAndDown"
                      color="accent"
                      v-model="tabs"
                    >
                      <v-tab>Giftcard</v-tab>
                      <v-tab>Crypto</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tabs">
                      <!-- Giftcard -->
                      <v-tab-item class="pt-2">
                        <v-autocomplete
                          v-model="selectedDigitalCurrency"
                          label="Digital Assets"
                          :items="cards"
                          item-text="name"
                          item-value="_id"
                          @change="digitalCurrencyChange"
                        >
                          <template v-slot:item="{ item }">
                            <v-list-item-avatar tile>
                              <v-img
                                width="40"
                                contain
                                :src="item.image"
                                alt="item.name"
                              />
                            </v-list-item-avatar>
                            <v-list-item-content
                              v-text="item.name"
                            ></v-list-item-content> </template
                        ></v-autocomplete>
                        <v-autocomplete
                          v-model="selectedCountry"
                          label="Country"
                          item-text="countryName"
                          item-value="id"
                          :items="availableCountry"
                          :disabled="availableCountry.length === 0"
                        >
                          <template v-slot:item="{ item }">
                            <v-list-item-avatar tile>
                              <v-img
                                width="40"
                                height="30"
                                contain
                                :src="
                                  objectImage[item.countryName.toLowerCase()]
                                "
                              ></v-img>
                            </v-list-item-avatar>
                            <v-list-item-content
                              v-text="item.countryName"
                            ></v-list-item-content>
                          </template>
                        </v-autocomplete>
                        <v-autocomplete
                          v-model="selectedCardType"
                          :items="availableCardTypes"
                          item-text="cardType"
                          item-value="_id"
                          label="Card Type"
                          @change="changeCardType"
                        >
                          <template v-slot:item="{ item }">
                            <v-list-item-avatar tile>
                              <v-img
                                width="40"
                                contain
                                src="@/assets/img/card-type.7a408e45.svg"
                              />
                            </v-list-item-avatar>
                            <v-list-item-content
                              v-text="item.cardType"
                            ></v-list-item-content> </template
                        ></v-autocomplete>
                        <v-text-field
                          hint="Minimum of $10, maximum of $5000"
                          v-model="totalAmount"
                          label="Amount($)"
                        ></v-text-field>
                        <v-slider
                          max="5000"
                          min="0"
                          v-model="totalAmount"
                          thumb-color="accent"
                          color="accent"
                          track-color="accent"
                        >
                          <template v-slot:prepend>
                            <small>$0</small>
                          </template>
                          <template v-slot:append>
                            <small>$5000</small>
                          </template>
                        </v-slider>
                      </v-tab-item>
                      <!-- Crypto -->
                      <v-tab-item class="pt-2">
                        <v-select
                          filled
                          v-model="selectedAction"
                          :items="['Sell', 'Buy']"
                          label="Choose action"
                        >
                        </v-select>
                        <v-autocomplete
                          filled
                          v-model="selectedCrypto"
                          label="Crypto Currency"
                          :items="currencies"
                          item-text="name"
                          item-value="_id"
                        >
                        </v-autocomplete>
                        <v-text-field
                          filled
                          v-model="totalAmountCrypto"
                          label="Amount($)"
                        ></v-text-field>
                        <v-slider
                          max="700"
                          min="0"
                          v-model="totalAmountCrypto"
                          thumb-color="accent"
                          color="accent"
                          track-color="accent"
                        >
                          <template v-slot:prepend>
                            <small>0</small>
                          </template>
                          <template v-slot:append>
                            <small>700</small>
                          </template>
                        </v-slider>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-card>
                </v-col>
                <v-col cols="12" md="5">
                  <v-card v-if="tabs == 0" class="pa-4" outlined>
                    <v-card-title>Trade Info</v-card-title>
                    <v-card-text>
                      <p>
                        Asset Type:
                        {{ cardData.name }}
                      </p>
                      <p>
                        Country:
                        {{
                          selectedCountry !== null
                            ? availableCountry.find(
                                (d) => d.id == selectedCountry
                              ).countryName
                            : ''
                        }}
                      </p>
                      <p>
                        Card Type:
                        {{
                          selectedCardType !== null
                            ? availableCardTypes.find(
                                (d) => d._id == selectedCardType
                              ).cardType
                            : ''
                        }}
                      </p>
                      <p>Asset Value: ${{ totalAmount }}</p>
                      <p class="mt-4 text-h6">TOTAL PAYOUT</p>
                      <p class="mt-4 text-h4 text--accent">
                        {{ userCurrency }} {{ totalSell }}
                      </p>
                      <p>
                        Rate:
                        <span class="overline text--accent">
                          {{ userCurrency }}
                          {{ nairaRateGiftcard || 0 }}/$</span
                        >
                      </p>
                    </v-card-text>
                  </v-card>
                  <!-- Tab for Crypto -->
                  <v-card v-else class="pa-4" outlined>
                    <v-card-title>Trade Info</v-card-title>
                    <v-card-text>
                      <p>
                        Action:
                        <span class="font-weight-bold accent--text">
                          {{ selectedAction }}</span
                        >
                      </p>
                      <p>Asset Type: Crypto currency</p>
                      <p>
                        COIN Name:
                        <span class="font-weight-bold accent--text">
                          {{
                            selectedCrypto
                              ? currencies.find((c) => c._id === selectedCrypto)
                                  .name
                              : ''
                          }}
                        </span>
                      </p>
                      <p>Asset Value: ${{ totalAmountCrypto }}</p>
                      <p class="mt-4 text-h6">TOTAL PAYOUT</p>
                      <p class="mt-4 text-h4 text--accent">
                        {{ userCurrency }} {{ totalSellCrypto }}
                      </p>
                      <p>
                        Rate:
                        <span class="overline text--accent">
                          {{ userCurrency }} {{ nairaRateCrypto }}/$</span
                        >
                      </p>
                    </v-card-text>
                  </v-card>

                  <v-btn
                    v-if="tabs == 0"
                    :to="`${
                      selectedDigitalCurrency
                        ? `/user/products/giftcards/sell/${selectedDigitalCurrency}`
                        : ''
                    }`"
                    class="mt-4"
                    depressed
                    block
                    color="accent"
                    large
                  >
                    Sell now
                  </v-btn>
                  <v-btn
                    v-else
                    :to="`${
                      selectedCrypto
                        ? `/user/products/crypto/trade/${selectedCrypto}`
                        : ''
                    }`"
                    class="mt-4"
                    depressed
                    block
                    color="accent"
                    large
                  >
                    Trade now
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import axios from 'axios'
import dataApi from '../../api/systemData'
import { numberWithCommas } from '../../utils/helpers'

import countryFlag from '../../utils/countryFlags'
import { mapState } from 'vuex'
// import { COUNTRIES } from '../../utils/countryHelpers'

export default {
  data: () => ({
    cardData: {},
    selectedDigitalCurrency: null,
    selectedCountry: null,
    selectedCardType: null,
    chooseDigitalCurrency: null,
    tabs: 0,
    selectedCrypto: '',
    selectedAction: '',
    currencies: [],
    totalAmount: 0,
    totalAmountCrypto: 0,

    nairaRate: 0,
    digitalCurrency: [],
    counties: [],
    cardTypes: [
      { name: 'Physical Card' },
      { name: 'E-code' },
      { name: 'Large Card' }
    ],
    cards: [],
    selected: {
      cardName: '',
      cardCounty: '',
      cardType: ''
    },
    countriesWithData: [],
    objectImage: {}
  }),
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      siteData: (state) => state.user.siteSettings,
      countrySetting: (state) => state.user.countryCurrency
    }),
    nairaRateCrypto() {
      if (this.selectedAction === '') return 0
      if (this.selectedCrypto === '') return 0

      const coin = this.currencies.find((c) => c._id === this.selectedCrypto)

      if (this.selectedAction.toLowerCase() === 'buy') {
        return Number(
          coin.buy /
            this.countrySetting[this.userCountry.toLowerCase()].nairaRate
        ).toFixed(2)
      } else if (this.selectedAction.toLowerCase() === 'sell') {
        return Number(
          coin.sell /
            this.countrySetting[this.userCountry.toLowerCase()].nairaRate
        ).toFixed(2)
      } else {
        return 0
      }
    },
    nairaRateGiftcard() {
      if (this.selectedCardTypeOptions.length !== 0) {
        const optionAvailable = this.selectedCardTypeOptions.find(
          (option) =>
            this.totalAmount >= option.minimum &&
            this.totalAmount <= option.maximum
        )

        const valueToReturn =
          optionAvailable !== undefined
            ? optionAvailable.nairaRate
            : this.selectedCardTypeOptions.find(
                (option) => option.default === true
              ).nairaRate ||
              this.selectedCardTypeOptions.find(
                (option) => option.default === true
              ).value

        return Number(
          valueToReturn /
            this.countrySetting[this.userCountry.toLowerCase()].nairaRate
        ).toFixed(2)
      }
      return 0
    },
    selectedCardTypeData() {
      if (this.selectedCardType !== '') {
        return this.availableCardTypes.find(
          (d) => d._id === this.selectedCardType
        )
      }
      return {}
    },
    selectedCardTypeOptions() {
      if (
        this.selectedCardTypeData !== undefined &&
        Object.keys(this.selectedCardTypeData).length > 0
      ) {
        return this.selectedCardTypeData.options
      }
      return []
    },
    availableCardTypes() {
      if (this.selectedCountry && Object.keys(this.cardData).length !== 0) {
        return this.cardData.options.find(
          (op) => this.selectedCountry === op._id
        ).cardTypes
      }
      return []
    },
    availableCountry() {
      if (this.cardData.options) {
        return this.cardData.options.map((op) => ({
          id: op._id,
          countryName: op?.country?.name,
          currencySymbol: op?.currency?.symbol,
          currencyLabel: op?.currency?.cc
        }))
      }
      return []
    },
    totalSellCrypto() {
      return numberWithCommas(this.nairaRateCrypto * this.totalAmountCrypto)
    },
    totalSell() {
      console.log(this.nairaRateGiftcard, this.totalAmount)
      return numberWithCommas(this.nairaRateGiftcard * this.totalAmount)
    }
  },
  created() {
    this.getAllGiftcard()
    this.getAllCrypto()
    this.$store.dispatch('user/getCountryCurrency')
    this.objectImage = countryFlag
  },
  methods: {
    changeCardType() {
      if (this.chooseDigitalCurrency) {
        // if (this.selectedCountry) {
        //   this.nairaRate = this.chooseDigitalCurrency.options.find(
        //     (op) => this.selectedCountry === op.country._id
        //   ).nairaRate
        // }
      }
    },
    digitalCurrencyChange() {
      this.selectedCountry = null
      this.selectedCardType = null
      this.cardData = this.cards.find(
        (card) => card._id === this.selectedDigitalCurrency
      )

      // this.cardData =

      // this.chooseDigitalCurrency = this.cards.find(
      //   (f) => f._id === this.selectedDigitalCurrency
      // )
      // this.counties = this.chooseDigitalCurrency.options.map((op) => ({
      //   ...op.country
      // }))
      // this.nairaRate = this.cardData.options.find(
      //   (op) => this.value === op.country._id
      // ).nairaRate
      // this.totalAmount = 0
    },
    async getAllGiftcard() {
      this.loading = true
      const res = await dataApi.data().getData('user', {
        type: 'giftcard',
        populate: ['country', 'currency']
      })
      this.cards = res.data.data.data.filter((card) => card.status === true)
      this.loading = false
    },
    async getAllCrypto() {
      const res = await dataApi.data().getData('user', { type: 'currency' })
      this.currencies = res.data.data.data
        .filter((c) => c.status === true)
        .sort((a, b) => {
          const textA = a.name.toLowerCase()
          const textB = b.name.toLowerCase()
          return textA < textB ? -1 : textA > textB ? 1 : 0
        })
    }
  }
}
</script>

<style></style>
